import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.deep,
		position: 'fixed',
		right: 0,
		bottom: 0,
		top: 0,
		left: 0,
		backgroundColor: theme.curtain.color,
	},
}));
