import { FC, useMemo, useState, useCallback, ReactElement } from 'react';

import { Modal as MUIModal, ModalProps as MUIModalProps } from '@mui/base/Modal';
import Grow from '@mui/material/Grow';
import { NewTheme } from '@vakantiesnl/components/src/styles/themes/zoover/newTheme';
import { InView } from 'react-intersection-observer';

import { useCustomStyles } from './Modal.style';
import { Backdrop } from '../Backdrop';
import { Button } from '../Button';
import { Typography } from '../Typography';

export type ModalProps = MUIModalProps & {
	/** Modal size/variant */
	size?: keyof NewTheme['modal']['width'];
	/** Title to show on the left in line with the close button */
	title?: string;
	/** Function to be called when closing the modal */
	onModalClose?: VoidFunction;
	/** Whether to show the close button */
	hasCloseIcon?: boolean;
	/** Whether to show the top bar */
	hasTopBar?: boolean;
	/** Whether the modal is full with indentation*/
	isIndentedModal?: boolean;
	/** React component that's leading */
	LeadingComponent?: ReactElement;
	/** Styling classname for the appbar title */
	titleClassName?: string;
};

export const Modal: FC<ModalProps> = ({
	open,
	size = 'medium',
	className,
	title,
	onModalClose,
	hasCloseIcon,
	hasTopBar = true,
	children,
	isIndentedModal,
	LeadingComponent,
	titleClassName,
	...props
}) => {
	const { classes, cx, theme } = useCustomStyles({ size });

	const [isContentScrolled, setIsContentScrolled] = useState(false);

	const modalSlots: MUIModalProps['slots'] = useMemo(
		() => ({
			backdrop: Backdrop,
		}),
		[],
	);

	const handleOnInViewChange = useCallback((inView: boolean) => {
		setIsContentScrolled(inView);
	}, []);

	return (
		<MUIModal
			open={open}
			onClose={onModalClose}
			className={cx(classes.modal, isIndentedModal && classes.indentedModal, className)}
			slots={modalSlots}
			aria-labelledby="modal"
			{...props}
		>
			<Grow
				className={cx(classes.container, { [classes.fullModalVariant]: size === 'full' })}
				in={open}
				easing={theme.animation.curve.easeInOut}
				timeout={200}
			>
				<div>
					<div className={classes.contentContainer}>
						{hasTopBar && (
							<div
								className={cx(classes.topBar, {
									[classes.shadow]: !isContentScrolled && !!title?.length && hasCloseIcon,
									[classes.topBarWithLeadingComponent]: !!LeadingComponent,
								})}
							>
								{LeadingComponent && <div className={classes.leadingWrapper}>{LeadingComponent}</div>}
								{title && (
									<div className={classes.titleWrapper}>
										<Typography
											variant="headingMd"
											as="h3"
											className={cx(classes.title, titleClassName)}
										>
											{title}
										</Typography>
									</div>
								)}
								{hasCloseIcon && (
									<div className={classes.closeButtonWrapper}>
										<Button
											variant="tertiary"
											leadingIcon="cancel"
											onClick={onModalClose}
											data-cy="modal-close-button"
										/>
									</div>
								)}
							</div>
						)}
						<div className={classes.content}>
							<InView initialInView onChange={handleOnInViewChange}>
								<span className={classes.observeElement} />
							</InView>
							{children}
						</div>
					</div>
				</div>
			</Grow>
		</MUIModal>
	);
};
