import { FC } from 'react';

import { useCustomStyles } from './Divider.style';

interface Props {
	className?: string;
	noBorder?: boolean;
	noMargins?: boolean;
}

export const Divider: FC<Props> = ({ noBorder, className, noMargins }) => {
	const { classes, cx } = useCustomStyles();

	return (
		<hr
			className={cx(
				classes.divider,
				{
					[classes.noBorder]: noBorder,
					[classes.margins]: !noMargins,
				},
				className,
			)}
		/>
	);
};
