import { FC, forwardRef } from 'react';

import { ModalOwnerState } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';

import { useCustomStyles } from './Backdrop.style';

export type BackdropProps = { open?: boolean; ownerState?: ModalOwnerState; onClick?: VoidFunction; classes?: string };

export const Backdrop: FC<BackdropProps> = forwardRef<HTMLDivElement, BackdropProps>(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ open, ownerState: _unused, ...other }, ref) => {
		const { classes, theme } = useCustomStyles();

		return (
			<Fade in={open} easing={theme.animation.curve.easeInOut} timeout={200}>
				<div {...other} className={classes.backdrop} ref={ref} />
			</Fade>
		);
	},
);

Backdrop.displayName = 'Backdrop';
