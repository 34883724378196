import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	divider: {
		border: 'none',
		borderTop: theme.divider.border,
		margin: 0,
	},

	margins: {
		margin: `${theme.spacing.small} 0`,
		[getFromBreakpoint('md')]: {
			margin: `${theme.spacing.medium} 0`,
		},
	},

	noBorder: {
		borderTop: 'none',
	},
}));
