import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import { ModalProps } from './Modal';

export const useCustomStyles = makeStyles<
	Pick<ModalProps, 'size' | 'isIndentedModal'>,
	'container' | 'content' | 'titleWrapper' | 'contentContainer' | 'topBar'
>()((theme, { size = 'medium' }, classes) => ({
	modal: {
		position: 'fixed',
		zIndex: theme.zIndex.floating,
		right: 0,
		bottom: 0,
		top: 0,
		left: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	indentedModal: {
		[`.${classes.container}`]: {
			width: '90%',
			height: '90%',
			maxHeight: '100vh',
		},
		[`.${classes.content}`]: {
			padding: 0,
		},
	},
	container: {
		boxSizing: 'border-box',
		borderRadius: theme.modal.borderRadius,
		overflow: 'hidden',
		width: theme.modal.width[size],
		maxHeight: `calc(100dvh - ${theme.modal.topBar.height.default})`,
	},
	fullModalVariant: {
		maxHeight: '100dvh',
		borderRadius: 0,
		height: '100dvh',

		[` .${classes.content}`]: {
			maxHeight: `calc(100dvh - ${theme.modal.topBar.height.fullScreen.mobile})`,
			padding: 0,

			[getFromBreakpoint('lg')]: {
				maxHeight: `calc(100dvh - ${theme.modal.topBar.height.fullScreen.desktop})`,
			},
		},
		[` .${classes.topBar}`]: {
			padding: `${theme.spacing.tiny} ${theme.spacing.xxsmall}`,
			height: theme.modal.topBar.height.fullScreen.mobile,
			boxSizing: 'border-box',
			backgroundColor: theme.modal.background.color,
			maxHeight: '100dvh',
			position: 'relative',

			[getFromBreakpoint('lg')]: {
				padding: theme.spacing.small,
				height: theme.modal.topBar.height.fullScreen.desktop,
			},
		},
	},
	observeElement: {
		width: 0,
		height: 0,
	},
	contentContainer: {
		boxSizing: 'border-box',
		backgroundColor: theme.modal.background.color,
		boxShadow: theme.modal.elevation,
		height: '100%',
		maxHeight: size === 'full' ? '100dvh' : `calc(100dvh - ${theme.modal.topBar.height.default})`,
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
	},
	topBar: {
		display: 'grid',
		gridTemplateColumns: 'minmax(60%, max-content) auto',
		gridTemplateAreas: "'title closeButtonWrapper'",
		alignItems: 'center',
		position: 'sticky',
		top: 0,
		backgroundColor: theme.modal.background.color,
		padding: theme.spacing.small,
		height: theme.modal.topBar.height.default,
		boxSizing: 'border-box',

		[getFromBreakpoint('lg')]: {
			height: theme.modal.topBar.height.default,
		},
	},
	title: {
		color: theme.modal.title.color,
		margin: `${theme.spacing.xsmall} 0`,
	},
	content: {
		boxSizing: 'border-box',
		minHeight: 0,
		padding: theme.spacing.small,
		paddingTop: 0,
		overflowY: 'auto',
	},
	shadow: {
		boxShadow: theme.modal.elevation,
	},
	leadingWrapper: {
		gridArea: 'leadingWrapper',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	titleWrapper: {
		gridArea: 'title',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	closeButtonWrapper: {
		gridArea: 'closeButtonWrapper',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	topBarWithLeadingComponent: {
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridTemplateAreas: "'leadingWrapper title closeButtonWrapper'",

		[`.${classes.titleWrapper}`]: {
			justifyContent: 'center',
		},
	},
}));
