import { FC, useMemo, useState, useCallback } from 'react';

import { Modal as MUIModal, ModalProps as MUIModalProps } from '@mui/base/Modal';
import Slide from '@mui/material/Slide';
import { InView } from 'react-intersection-observer';

import { useCustomStyles } from './Drawer.style';
import { Backdrop } from '../Backdrop';
import { Button } from '../Button';
import { Typography } from '../Typography';

export type DrawerProps = MUIModalProps & {
	/** Alignment of the drawer */
	direction?: 'up' | 'down' | 'right';
	/** Title to show on the left in line with the close button */
	title?: string;
	/** Function to be called when closing the drawer */
	onDrawerClose?: VoidFunction;
	/** Whether to show the close button */
	hasCloseIcon?: boolean;
	/** Whether to show the back button */
	hasBackButton?: boolean;
	/** Whether the drawer is animated */
	withAnimation?: boolean;
	/** Classname to apply to the drawer container */
	containerClassName?: string;
	/** Classname to apply to the drawer content container */
	contentContainerClassName?: string;
	/** Classname to apply to the drawer content */
	contentClassName?: string;
	isFullHeight?: boolean;
	submitButtonProps?: {
		onSubmit: () => void;
		isDisabled: boolean;
		label: string;
	};
};

export const Drawer: FC<DrawerProps> = ({
	open,
	direction = 'up',
	className,
	title,
	onDrawerClose,
	hasCloseIcon,
	children,
	withAnimation = true,
	containerClassName,
	contentContainerClassName,
	contentClassName,
	hasBackButton,
	isFullHeight,
	submitButtonProps,
	...props
}) => {
	const { classes, cx, theme } = useCustomStyles({ direction, isFullHeight });

	const [isContentScrolled, setIsContentScrolled] = useState(false);

	const drawerSlots: MUIModalProps['slots'] = useMemo(
		() => ({
			backdrop: Backdrop,
		}),
		[],
	);

	const handleOnInViewChange = useCallback((inView: boolean) => {
		setIsContentScrolled(inView);
	}, []);

	return (
		<MUIModal
			open={open}
			onClose={onDrawerClose}
			className={cx(classes.drawer, className)}
			slots={drawerSlots}
			aria-labelledby="drawer"
			closeAfterTransition
			{...props}
		>
			{open ? (
				<Slide
					className={cx(classes.container, containerClassName)}
					in={open}
					direction={direction}
					appear={withAnimation}
					easing={withAnimation ? theme.animation.curve.easeInOut : undefined}
					timeout={withAnimation ? 200 : undefined}
				>
					<div>
						<div
							className={cx(classes.contentContainer, contentContainerClassName)}
							data-cy="drawer-content"
						>
							{(title || hasCloseIcon) && (
								<div
									className={cx(classes.topBar, {
										[classes.shadow]: !isContentScrolled && !!title?.length && hasCloseIcon,
									})}
								>
									{hasBackButton && (
										<Button
											variant="tertiary"
											leadingIcon="arrowLeft"
											onClick={onDrawerClose}
											className={classes.backButton}
											size="small"
											data-cy="drawer-back-button"
										/>
									)}
									{title && (
										<Typography variant="headingLg" as="h3" className={classes.title}>
											{title}
										</Typography>
									)}
									{hasCloseIcon && (
										<Button
											className={classes.closeButton}
											variant="tertiary"
											leadingIcon="cancel"
											onClick={onDrawerClose}
											size="small"
										/>
									)}
								</div>
							)}
							<div className={cx(classes.content, contentClassName)}>
								<div className={classes.contentTop}>
									<InView initialInView onChange={handleOnInViewChange}>
										<span className={classes.observeElement} />
									</InView>
									{children}
								</div>
								{submitButtonProps && (
									<div className={classes.submitButton}>
										<Button
											size="small"
											onClick={submitButtonProps.onSubmit}
											variant="primary"
											disabled={submitButtonProps.isDisabled}
										>
											{submitButtonProps.label}
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				</Slide>
			) : (
				<></>
			)}
		</MUIModal>
	);
};
